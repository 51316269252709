<template>
  <div class="mt-5">
    <div
      v-if="$apolloData.queries.doctor.loading"
      ref="isLoading"
      class="text-center text-muted vertical-centered"
    >
      <font-awesome-icon
        :icon="['fas', 'circle-notch']"
        class="fa-spin fa-5x my-4"
      />
      <small class="d-block">
        CARGANDO
      </small>
    </div>
    <div
      v-else-if="doctor && Object.entries(doctor).length && doctor.fname"
      ref="elseIfDoctorLength"
      class="user-profile"
      :style="`background-color: ${bgColor}; color: ${txtColor};` +
        `transition: all .5s ease;`"
    >
      <div class="container">
        <main class="user-personal-info">
          <img
            class="rounded"
            :src="imgSrc"
            :alt="`Perfil de ${personNames.fullName}`"
          >
          <header class="mt-3 mt-md-0 ml-md-3">
            <h1 class="h1-responsive font-weight-bold">
              <span class="d-block d-md-inline mt-1">
                {{ personNames.names }}
              </span>
              <span class="d-block d-md-inline mt-1 ml-1">
                {{ personNames.lnames }}
              </span>
            </h1>
            <p class="h4-responsive font-italic">
              {{ searchTags() }}
            </p>
          </header>
        </main>
        <div
          :class="['text-center',
                   {'animated shake delay-5s': addBtnCitaAnimation}]"
        >
          <mdb-btn
            ref="mdbBtnShoaAgendarCita"
            class="my-3"
            color="success-base"
            icon="calendar-alt"
            @click="showAgendarCita = true"
          >
            {{ doctor.profileInfo.buttonLabel || 'Solicitar cita' }}
          </mdb-btn>
        </div>
        <pre
          class="my-md-3 roboto-text pre-wrap currentColor"
        >{{ doctor.profileInfo.wysiwyg }}</pre>
        <div
          v-if="doctor.contactsInfo.length"
          ref="vIfDoctorContactsInfoLength"
          class="bg-card"
        >
          <p class="h5-responsive font-weight-bold text-center">
            Contacto
          </p>
          <ul class="contact-list">
            <li
              v-for="(contact, iContact) in doctor.contactsInfo"
              :key="iContact"
              :class="[
                'contact',
                doctor.contactsInfo.length > 2 ? 'split-3' : 'split-2',
                contact.icon
              ]"
            >
              <span class="title">{{ contact.name }}</span>
              <a
                :href="contact.url"
                target="_blank"
                class="currentColor"
                rel="noopener noreferrer nofollow"
                :title="contact.title"
              >
                {{ contact.value }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      v-else
      class="py-4 container vertical-centered text-muted"
    >
      <font-awesome-icon
        :icon="['fas', 'user-slash']"
        class="my-2 fa-5x"
      />
      <header class="h2-responsive font-weight-bold">
        ¡Lo sentimos!
      </header>
      <p class="lead text-center">
        <span class="d-sm-block">
          El doctor que buscas no ha configurado
        </span>
        <span class="d-sm-block">
          su perfil público o está mal escrito el enlace.
        </span>
      </p>
      <p class="text-center">
        Búscalo a través de nuestro directorio médico y
        <span class="d-sm-block">
          descubre más especialistas en todas las áreas.
        </span>
      </p>
      <router-link
        v-slot="{ navigate }"
        :to="{ name: 'home' }"
        custom
      >
        <span
          role="link"
          class="btn btn-primary-base px-3"
          @click="navigate"
          @keypress.enter="navigate"
        >
          <font-awesome-icon
            icon="chevron-left"
            class="mr-1"
          />
          Ver directorio médico
        </span>
      </router-link>

      <p class="h3-responsive font-weight-bold my-2">
        ¿Eres el médico?
      </p>
      <p class="text-sm-center">
        Te invitamos a crear tu perfil público a través del sistema,
        <span class="d-sm-block">
          en la sección de Configuración > Perfil público.
        </span>
      </p>
    </div>
    <ModalAgendarCita
      :show-modal="showAgendarCita"
      :doctor="doctor"
      @close="showAgendarCita = $event"
    />
  </div>
</template>
<script>
import {mdbBtn} from 'mdbvue';
import ModalAgendarCita from '@/components/modalAgendarCita.vue';
import {capitalizeAll} from '@/utils/validations.js';
import gql from 'graphql-tag';
import doctorProfileGql from '@/graphql/doctorProfile.gql';

const imgPath = process.env.NODE_ENV === 'production'
              ? process.env.VUE_APP_MULTIMEDIA_HTTPS
              : process.env.VUE_APP_MULTIMEDIA_HTTP;

export default {
  name: 'PerfilDeUsuario',
  components: {
    mdbBtn,
    ModalAgendarCita,
  },
  data() {
    return {
      doctor: {
        profileInfo: {},
        contactsInfo: [],
        works_at: [],
      },
      showAgendarCita: false, // Abre modal agendar cita
      addBtnCitaAnimation: false,
      imgPath: imgPath+'dir/',
    };
  },
  computed: {
    bgColor() {
      return this.doctor.profileInfo.bgColor
        ? this.doctor.profileInfo.bgColor
        : 'rgb(255, 255, 255)';
    },
    imgSrc() {
      return this.doctor.profileInfo.image
        ? this.imgPath+this.doctor.profileInfo.image.url
        : `https://www.gravatar.com/avatar/${this.doctor.profileInfo.avatar}?s=150`;
    },
    txtColor() {
      return this.doctor.profileInfo.color
        ? this.doctor.profileInfo.color
        : 'rgb(33, 37, 41)';
    },
    contactListLength() {
      const longitud = this.doctor.contactsInfo.length;
      return longitud > 2 ? 'columns3' : longitud == 2 ? 'columns2' : '';
    },
    personNames() {
      return {
        names: `${this.doctor.prename ? this.doctor.prename + ' ' : ''}`
          + `${this.doctor.fname}`
          + `${this.doctor.mname ? ' ' + this.doctor.mname : ''}`,
        lnames: `${this.doctor.lname}`
          + `${this.doctor.llname ? ' ' + this.doctor.llname : ''}`
          + `${this.doctor.posname ? ' ' + this.doctor.posname : ''}`,
        fullName: `${this.doctor.prename ? this.doctor.prename+' ' : ''}`
          + `${this.doctor.fname}`
          + `${this.doctor.mname ? ' ' + this.doctor.mname : ''}`
          + ` ${this.doctor.lname}`
          + `${this.doctor.llname ? ' ' + this.doctor.llname : ''}`
          + `${this.doctor.posname ? ' ' + this.doctor.posname : ''}`,
      };
    },
  },
  mounted() {
    this.addBtnCitaAnimation = true;
  },
  methods: {
    searchTags() {
      let tags = '';
      if (this.doctor.profileInfo
        && this.doctor.profileInfo.searchTags
        && this.doctor.profileInfo.searchTags.length
        && this.doctor.profileInfo.searchTags[0].name) {
        this.doctor.profileInfo.searchTags.map((tag) => {
          if (tags.length > 0) tags += ', ';
          tags += capitalizeAll(tag.name);
        });
      }
      return tags;
    },
  },
  apollo: {
    doctor() {
      return {
        query: gql`${doctorProfileGql}`,
        variables: {
          usuario: this.$route.params.usuario.toLowerCase(),
        },
        update: (data) => data.Doctor[0],
      };
    },
  },
};
</script>
<style lang="scss">
  // family className content weight rotation
  $links-type: (
    "Font Awesome 5 Brands" "facebook" "\f09a" 0 false,
    "Font Awesome 5 Brands" "instagram" "\f16d" 0 false,
    "Font Awesome 5 Brands" "linkedIn" "\f08c" 0 false,
    "Font Awesome 5 Brands" "telegram" "\f2c6" 0 false,
    "Font Awesome 5 Brands" "tiktok" "\e07b" 0 false,
    "Font Awesome 5 Brands" "twitter" "\f099" 0 false,
    "Font Awesome 5 Brands" "whatsApp" "\f232" 0 false,
    "Font Awesome 5 Brands" "youtube" "\f167" 0 false,
    "Font Awesome 5 Free" "book" "\f02d" 900 false,
    "Font Awesome 5 Free" "email" "\f0e0" 400 false,
    "Font Awesome 5 Free" "file" "\f15b" 900 false,
    "Font Awesome 5 Free" "filePdf" "\f1c1" 900 false,
    "Font Awesome 5 Free" "link" "\f0c1" 900 false,
    "Font Awesome 5 Free" "phone" "\f879" 900 rotate(80deg),
  );

  /*
    .user-profile
    Establece altura mínima de la section para poder aplicar
    el color de fondo en toda la pantalla
  */
  .user-profile {
    min-height: calc(100vh - 48px);
    padding: 1.5rem 0 1rem;
  }

  .user-personal-info {
    text-align: center;
    img {
      max-width: 150px;
    }

    @media screen and (min-width: 768px) {
      align-items: center;
      display: flex;
      justify-content: center;
      text-align: left;
    }
  }

  .bg-card {
    background-color: rgba(191, 187, 187, 0.2);
    border-radius: 5px;
    margin: 1.5rem 0;
    padding: 1rem;
  }

  .contact-list {
    list-style-type: none;

    @media screen and (min-width: 768px) {
      display: flex;
      justify-content: space-between;
      flex-flow: row wrap;
    }

    .contact {
      margin: .5rem 0 .5rem 26px;
      position: relative;

      &::before {
        position: absolute;
        left: -20px;
      }

      @each $family, $class, $content, $weight, $transform in $links-type {
        &.#{$class}::before {
          content: $content;
          font-family: $family;

          @if $weight != 0 {
            font-weight: $weight;
          }

          @if $transform != false {
            transform: $transform;
          }
        }
      }

      &.split-2 {
        width: 40%;
      }
      &.split-3 {
        width: 30%;
      }

      .title {
        display: block;
        font-weight: 700;
      }
    }
  }
  .currentColor {
    color: currentColor;
  }
</style>
